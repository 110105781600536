<template lang="pug" functional>
div.calendar-date(:class="data.staticClass")
  div {{ props.date }}
  .calendar-date__subtitle {{ props.month }}
</template>

<script>
export default {
  props: {
    month: String,
    date: Number
  }
}
</script>

<style lang="scss">
.calendar-date {
  position: relative;
  padding: 6px;
  width: 37px;
  height: 37px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  color: $title-second;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  &__subtitle {
    color: $label-color;
    font-weight: bold;
    font-size: 8px;
  }
}

</style>